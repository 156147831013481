import { ICategory } from '../interfaces/vision-merchandise.interface';

export const categoriesData: ICategory[] = [
  {
    id: 1,
    name: 'Apparel',
    title: 'T-SHIRTS & SWEATSHIRTS',
    description: 'High quality and cozy street wear for your everyday use! Unique designs and comfortable oversized lines in basic colours available.',
    products: [
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/apparel/regular.png',
        category: 'Apparel',
        productName: 'Regular Fit T-Shirt',
        productPrice: 30,
      },
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/apparel/oversized.png',
        category: 'Apparel',
        productName: 'Oversized T-Shirt',
        productPrice: 35,
      },
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/apparel/sweatshirt.png',
        category: 'Apparel',
        productName: 'Sweatshirt',
        productPrice: 45,
      },
    ],
  },
  {
    id: 2,
    name: 'Headwear',
    title: 'CAPS & HATS',
    description: `Looking to put your swag on? We've got 3 headwear designs guaranteed to help you match i-Con's vibes! Take your pick from our high quality hat selection below!`,
    products: [
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/headwear/truckers.png',
        category: 'Headwear',
        productName: 'Truckers Cap',
        productPrice: 18,
      },
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/headwear/snapback.png',
        category: 'Headwear',
        productName: 'Snapback Cap',
        productPrice: 22,
      },
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/headwear/bucket.png',
        category: 'Headwear',
        productName: 'Bucket Hat',
        productPrice: 32,
      },
    ],
  },
  {
    id: 3,
    name: 'Eyewear',
    title: 'SUNGLASSES',
    description:
      'Eyewear sunglasses and eyeglasses are designed in Milan and produced in Cadore Valley, by expert artisans with sustainable materials. Classic and vintage shapes are reinterpreted in a modern way, featuring a variety of colors for frames and lenses.',
    products: [
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/eyewear/1.png',
        category: 'Eyewear',
        productName: 'Sunglasses',
        productPrice: 160,
        productPrice2: 175,
      },
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/eyewear/2.png',
        category: 'Eyewear',
        productName: 'Eyeglasses',
        productPrice: 160,
        productPrice2: 175,
      },
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/eyewear/3.png',
        category: 'Eyewear',
        productName: 'Polarized',
        productPrice: 160,
        productPrice2: 175,
      },
    ],
  },
  {
    id: 4,
    name: 'Accessories',
    title: 'HAND FANS & TOWELS',
    description: '100% handmade accessories. A handy fan to cool you off and a superb towel for your summer time!',
    products: [
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/accessories/handfan.png',
        category: 'Accessories',
        productName: 'Hand Fan',
        productPrice: 6,
      },
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/accessories/towel.png',
        category: 'Accessories',
        productName: 'Handmade Towel',
        productPrice: 32,
      },
    ],
  },
  {
    id: 5,
    name: 'Swimwear',
    title: 'SWIMWEAR',
    description: 'One of the most trending items of the summer! We are making sure that your days by the beautiful coasts of Cyprus will be chic and stylish.',
    products: [
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/swimwear/mens_new.jpg',
        category: 'Swimwear',
        productName: `Men's swimwear`,
        productPrice: 35,
      },
      {
        imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/swimwear/womens.png',
        category: 'Swimwear',
        productName: `Women's swimwear`,
        productPrice: 45,
      },
    ],
  },
];
