<div *ngIf="category" class="header-container"
    style="background-image: url(https://cdn-images.island-conference.com/vision/merchandise/categories/{{category.name.toLowerCase()}}/header.jpg)">
    <div class="content">
        <h1>{{category.name}}</h1>
    </div>
</div>
<div *ngIf="category" class="container">
    <div class="backBtn">
        <button [routerLink]="['/vision/merchandise']">Back</button>
    </div>
    <div class="titleAndDescription">
        <h2>{{category.title}}</h2>
        <p>{{category.description}}</p>
    </div>
    <div class="productContainer">
        <app-vision-merchandise-card class="item pointerEventsNone" *ngFor="let card of category.products"
            [card]="card"></app-vision-merchandise-card>
    </div>
</div>

<app-not-found *ngIf="!category"></app-not-found>