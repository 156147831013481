import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { VisionMerchandiseCategoryComponent } from './pages/vision-merchandise-category/vision-merchandise-category.component';
import { VisionMerchandiseComponent } from './pages/vision-merchandise/vision-merchandise.component';
import { VisionMerchandiseCardComponent } from './vision-merchandise-card/vision-merchandise-card.component';
import { VisionMerchandiseRoutingModule } from './vision-merchandise-routing.module';

@NgModule({
  declarations: [
    VisionMerchandiseComponent,
    VisionMerchandiseCardComponent,
    VisionMerchandiseCategoryComponent,
  ],
  imports: [CommonModule, VisionMerchandiseRoutingModule, SharedModule],
})
export class VisionMerchandiseModule {}
