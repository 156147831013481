import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VisionMerchandiseCategoryComponent } from './pages/vision-merchandise-category/vision-merchandise-category.component';
import { VisionMerchandiseComponent } from './pages/vision-merchandise/vision-merchandise.component';

const routes: Routes = [
  {
    path: '',
    component: VisionMerchandiseComponent,
  },
  {
    path: ':category',
    component: VisionMerchandiseCategoryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VisionMerchandiseRoutingModule {}
