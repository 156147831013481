import { Component } from '@angular/core';
import { cardsData } from '../../data/vision-merchandise-data';
import { ICard } from '../../interfaces/vision-merchandise.interface';

@Component({
  selector: 'i-con.app-vision-merchandise',
  templateUrl: './vision-merchandise.component.html',
  styleUrls: ['./vision-merchandise.component.scss'],
})
export class VisionMerchandiseComponent {
  cards: ICard[] = cardsData;
  class_name = 'vision-merchandise';
  phrase = 'Have a look at';
  title1 = 'OUR MERCH';
}
