<div class="card" [routerLink]="[card.category!.toLowerCase()]">
    <img src="{{card.imageUrl}}"
        alt="{{!card.productName || card.productName === '' ? card.category : card.productName}}">
    <div class="cardText">
        <span *ngIf="!(card.productName && card.productPrice)">{{card.category}}</span>
        <span class="productDetails" *ngIf="card.productName">{{card.productName}}</span>
        <!-- <span class="productDetails" *ngIf="card.productPrice && !card.productPrice2">{{card.productPrice |
            currency:'EUR':"symbol":'1.0-0'
            }}</span>
        <span class="productDetails" *ngIf="card.productPrice && card.productPrice2">{{card.productPrice |
            currency:'EUR':"symbol":'1.0-0'
            }} - {{card.productPrice2 | currency:'EUR':"symbol":'1.0-0'
            }}</span> -->
    </div>
</div>