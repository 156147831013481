import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { categoriesData } from '../../data/vision-merchandise-category-data';
import { ICategory } from '../../interfaces/vision-merchandise.interface';

@Component({
  selector: 'i-con.app-vision-merchandise-category',
  templateUrl: './vision-merchandise-category.component.html',
  styleUrls: ['./vision-merchandise-category.component.scss'],
})
export class VisionMerchandiseCategoryComponent {
  categories: ICategory[] = categoriesData;
  category: ICategory;
  class_name = '';
  title1 = '';

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.category = this.categories.find(
        (c) => c.name.toLowerCase() === params.get('category')?.toLowerCase()
      )!;

      if (this.category) {
        this.title1 = this.category.title;
        this.class_name = `vision-merchandise-category ${this.category.name.toLowerCase()}`;
      }
    });
  }
}
