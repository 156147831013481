<div class="header-container">
    <div class="content">
        <h3>Have a look at</h3>
        <h1>OUR MERCH</h1>
    </div>
</div>

<div class="container">
    <div class="cardContainer">
        <app-vision-merchandise-card class="item" *ngFor="let card of cards"
            [card]="card"></app-vision-merchandise-card>
    </div>
</div>