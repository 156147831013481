import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICard } from '../interfaces/vision-merchandise.interface';

@Component({
  selector: 'app-vision-merchandise-card',
  templateUrl: './vision-merchandise-card.component.html',
  styleUrls: ['./vision-merchandise-card.component.scss'],
})
export class VisionMerchandiseCardComponent   {
  @Input() card!: ICard;

  constructor(private route: ActivatedRoute, private router: Router) {}
}
