import { ICard } from '../interfaces/vision-merchandise.interface';

export const cardsData: ICard[] = [
  {
    id: 1,
    imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/apparel/cover.png',
    category: 'Apparel',
  },
  {
    id: 2,
    imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/headwear/cover.png',
    category: 'Headwear',
  },
  {
    id: 3,
    imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/eyewear/cover.png',
    category: 'Eyewear',
  },
  {
    id: 4,
    imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/accessories/cover.png',
    category: 'Accessories',
  },
  {
    id: 5,
    imageUrl: 'https://cdn-images.island-conference.com/vision/merchandise/categories/swimwear/cover.png',
    category: 'Swimwear',
  },
];
